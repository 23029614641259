import React, {useState} from "react"
import styles from "../styles/scrapbook/flipimage.module.scss"

const FlipImage = (props) => {

    const [isToggled, setToggled] = useState(true);

    const toggleTrueFalse = () => setToggled(!isToggled);

    const toggleBack = () => setToggled(false);
    const toggleFront = () => setToggled(true);

    if (isToggled) { // currently showing front
        return (
            <div className={styles.base} onMouseEnter={toggleBack} style={{width: props.width}}>
                <img draggable="false" src={props.front}></img>
            </div>)
    } else {
        return (
            <div className={styles.base} onMouseOut={toggleFront} style={{width: props.width}}>
                <img draggable="false" src={props.back}></img>
            </div>)
    }
  
}

export default FlipImage