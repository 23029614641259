import React from "react"
import styles from "../styles/scrapbook.module.scss"

const Scrapbook = (props) => {

  return (
  <div className={styles.base}>
      {props.children}
  </div>)
}

export default Scrapbook