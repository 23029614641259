import React from "react"
import Scrapbook from "../../components/scrapbook"
import Draggable from "../../components/draggable"
import FlipImage from "../../components/flipimage"
import styles from "../../styles/scrapbook/germany.module.scss"

export default () => (
  <Scrapbook>
      
      <Draggable width="fit-content" display="inline-block" verticalAlign="top">
          <FlipImage width="200px" front="/scrapbook-images/germany/welcomecard-1.jpg" back="/scrapbook-images/germany/welcomecard-2.png"/>
      </Draggable>

      <Draggable width="fit-content" display="inline-block" verticalAlign="top">
          <FlipImage width="200px" front="/scrapbook-images/germany/cocolo-1.png" back="/scrapbook-images/germany/cocolo-2.png"/>
      </Draggable>

      <Draggable width="fit-content" display="inline-block" verticalAlign="top">
          <FlipImage width="300px" front="/scrapbook-images/germany/abc-1.png" back="/scrapbook-images/germany/abc-2.png"/>
      </Draggable>

      <Draggable width="fit-content" display="inline-block" verticalAlign="top">
          <div className={styles.note}>
              In February 2020, Beriwan and I went on a trip to Germany for a week during our school reading break. We visited Berlin and Frankfurt and it was fantastic!
          </div>
      </Draggable>
  </Scrapbook>
)